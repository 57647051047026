$twitch: #9146ff;
$dark: #000;
$light: #f0f0ff;
$break: 768px;

@mixin title($size) {
  text-align: center;
  font-size: min(#{$size}, 15vw);
  letter-spacing: -.04em;
  line-height: 125%;
  margin: 0;
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background: $twitch;
  color: $dark;
  font-size: 16px;
  font-family: 'Space Grotesk', sans-serif; // This should use Roobert but this is a free Google Fonts alternative
  font-weight: 600;
}

body {
  &,
  #content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #content {
    width: 100%;
    min-height: 100vh;
    z-index: 1;

    h1 {
      @include title(4em);
    }

    img {
      width: 8em;
      height: 8em;
    }
  }

  #history {
    width: 100%;
    padding: 4em 2em;

    h2 {
      @include title(3em);

      text-align: start;
      margin: 0 0 .5em;

      &:not(:first-of-type) {
        margin: 2em 0 .5em;
      }
    }

    > div {
      display: flex;
      flex-wrap: wrap;

      @media (min-width: $break) {
        padding: 0 2em;
      }

      > div {
        flex: 1 1 50%;
        padding: 1em 0;

        @media (min-width: $break) {
          padding: 1em 2em;
        }

        &:nth-child(5n),
        &:nth-child(5n + 1),
        &:nth-child(5n + 4) {
          h3 {
            color: $light;
          }
        }

        &:nth-child(5n + 2),
        &:nth-child(5n + 3) {
          h3 {
            color: $twitch;
          }
        }

        h3 {
          @include title(4em);

          text-align: start;
          margin: 0 0 .25em;
        }

        img {
          width: 10em;
          height: 10em;
          margin: 0 2em;
        }

        > div {
          margin: 4em 0 2em;
          background: rgba($light, 0.25);

          &,
          > div {
            height: 1em;
            border-radius: .5em;
          }

          > div {
            background: $twitch;
          }
        }
      }
    }
  }

  a {
    color: $light;
  }

  #footer {
    margin: 1em;
    z-index: 2;
    font-size: .75em;
    font-weight: 400;

    &,
    a {
      transition: color 200ms ease-in-out;
    }
  }

  &.anim-active {
    #content {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }

    #footer {
      position: fixed;
      bottom: 0;
    }
  }

  &.dark,
  &.post-anim {
    background: $dark;
    color: $light;

    a {
      color: $twitch;
    }
  }

  &.post-anim {
    #content {
      min-height: 75vh;
      flex-direction: column-reverse;
      align-items: normal;

      @media (min-width: $break) {
        flex-direction: row;
      }

      > div {
        width: 100%;

        @media (min-width: $break) {
          width: 50%;
        }

        &,
        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        // Text
        &:first-child {
          > div {
            padding: 2em;
            width: 100%;
            flex: 1 1 50%;

            &:first-child {
              flex-basis: 75%;
              background: $twitch;
              color: $dark;
            }

            &:last-child {
              h2 {
                @include title(5em);
              }

              a {
                position: relative;
                transition: color 150ms ease-in-out;

                &:hover {
                  color: $light;

                  span {
                    &:last-child {
                      svg {
                        &:first-child {
                          top: -1.5em;
                          right: -1.5em;
                        }

                        &:last-child {
                          top: 0;
                          right: 0;
                        }
                      }
                    }
                  }
                }

                span {
                  &:last-child {
                    position: relative;
                    display: inline-block;
                    width: .5em;
                    height: .5em;
                    margin: 0 .15em;
                    overflow: hidden;

                    svg {
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      transition: top 300ms ease-in-out, right 300ms ease-in-out;

                      &:first-child {
                        top: 0;
                        right: 0;
                        fill: $twitch;
                        stroke: $twitch;
                      }

                      &:last-child {
                        top: 1.5em;
                        right: 1.5em;
                        fill: $light;
                        stroke: $light;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        // PogChamp
        &:last-child {
          padding: 2em;
          background: $twitch;
          color: $dark;
        }
      }
    }

    #footer {
      color: $light;

      a {
        color: $twitch;
      }
    }
  }
}
